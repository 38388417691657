import SuspenseProgress from 'components/SuspenseProgress';
import { FC, Suspense } from 'react';

type SuspenseLoaderProps = {};

const SuspenseLoader =
    <T extends SuspenseLoaderProps>(Content: FC<T>) =>
    (props: T) =>
        (
            <Suspense fallback={<SuspenseProgress />}>
                <Content {...props} />
            </Suspense>
        );

export default SuspenseLoader;

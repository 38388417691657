import { Box, Fade, FadeProps } from '@mui/material';
import { FC } from 'react';

interface Props extends FadeProps {
    isFetching?: boolean;
}

export const CustomFade: FC<Props> = ({ children, in: _, isFetching, ...props }) => {
    return (
        <Fade in={!isFetching} timeout={1000} {...props}>
            <Box>{!isFetching && children}</Box>
        </Fade>
    );
};

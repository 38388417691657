import { createApi } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';
import { CheckoutSessionPayload, PaymentInfo, WorkspacesInfo } from 'models/Payment';
import { Recruitment, Session } from 'models/recruitment';
import axiosBaseQuery from 'store/axiosBaseQuery';
import { Stripe } from 'stripe';

export const paymentService = {
    hasActiveSubscription: (payment?: PaymentInfo): boolean => {
        if (!payment || !payment.subscription_id || payment.subscription_expiration_time === 0) {
            return false;
        }

        const expirationTime = dayjs.unix(payment.subscription_expiration_time);
        return dayjs().isBefore(expirationTime);
    },
    countCreatedWorkspaces: (recruitments: Recruitment[], userId: string): number => {
        const vmCount = recruitments.reduce((count: number, recruitment) => {
            count += recruitment.sessions.filter((session) => session.vm && session.vm.owner.id === userId).length;
            return count;
        }, 0);

        return vmCount;
    }
};

export const paymentApiService = createApi({
    reducerPath: 'paymentApiService',
    refetchOnMountOrArgChange: true,
    baseQuery: axiosBaseQuery({ basePath: '/payments' }),
    endpoints: (builder) => ({
        getMyPaymentInfo: builder.query<PaymentInfo, void>({
            query: () => ({ url: `/alias/me`, method: 'get' })
        }),
        getAvailableWorkspacesInfo: builder.query<WorkspacesInfo, void>({
            query: () => ({ url: `/alias/me/vms`, method: 'get' })
        }),
        getActiveProducts: builder.query<Stripe.Product[], void>({
            query: () => ({ url: `/products`, method: 'get' })
        }),
        getActiveSubscription: builder.query<Stripe.Subscription, string>({
            query: (id) => ({ url: `/subscriptions/${id}`, method: 'get' })
        }),
        getProduct: builder.query<Stripe.Product, string>({
            query: (id) => ({ url: `/products/${id}`, method: 'get' })
        }),
        getPrice: builder.query<Stripe.Price, string>({
            query: (id) => ({ url: `/prices/${id}`, method: 'get' })
        }),
        createCustomer: builder.mutation<Stripe.Customer, Stripe.CustomerCreateParams>({
            query: (data) => ({
                url: `/customers`,
                method: 'post',
                data
            })
        }),
        createCheckoutSession: builder.mutation<Stripe.Checkout.Session, CheckoutSessionPayload>({
            query: (data) => ({
                url: `/checkout/sessions`,
                method: 'post',
                data
            })
        }),
        createBillingPortalSession: builder.mutation<Stripe.BillingPortal.Session, string>({
            query: (customerId) => ({
                url: `/billing_portal/sessions?customer=${customerId}`,
                method: 'post'
            })
        })
    })
});

export const {
    useGetMyPaymentInfoQuery,
    useGetAvailableWorkspacesInfoQuery,
    useGetActiveProductsQuery,
    useGetActiveSubscriptionQuery,
    useGetProductQuery,
    useGetPriceQuery,
    useCreateCustomerMutation,
    useCreateCheckoutSessionMutation,
    useCreateBillingPortalSessionMutation
} = paymentApiService;

import { MenuEntry } from 'models/menu';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { alpha, Box, Button, Divider, List, ListItem, ListItemText, Popover, styled, Typography } from '@mui/material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';

import { useAppDispatch } from 'hooks/store';
import { useAuth } from 'hooks/useAuth';
import { clearCredentials } from 'store/slices/authSlice';
import { isLightMode } from 'theme/ThemeProvider';
import SecureAvatar from 'components/SecureAvatar';
import { recruitmentService } from 'services/recruitmentService';

const UserBoxButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.colors.alpha.trueWhite[50],
    backgroundColor: theme.colors.alpha[isLightMode(theme) ? 'white' : 'black'][10],
    height: 48,
    minWidth: 'auto',
    borderRadius: theme.general.borderRadiusLg,

    '.MuiSvgIcon-root': {
        transition: theme.transitions.create(['color']),
        fontSize: theme.typography.pxToRem(24),
        color: theme.colors.alpha.trueWhite[50]
    },

    '.MuiAvatar-root': {
        borderRadius: theme.general.borderRadiusLg,
        width: 34,
        height: 34
    },

    '&.Mui-active, &:hover': {
        backgroundColor: alpha(theme.colors.alpha[isLightMode(theme) ? 'white' : 'black'][30], 0.2),

        '.MuiSvgIcon-root': {
            color: theme.colors.alpha.trueWhite[100]
        }
    },

    '.MuiButton-label': {
        justifyContent: 'flex-start'
    }
}));

const UserBoxPopover = styled(Popover)(({ theme }) => ({
    '.MuiPaper-root': {
        background: theme.colors.alpha.white[100]
    }
}));

const MenuUserBox = styled(Box)(({ theme }) => ({
    background: theme.colors.alpha.black[5],
    padding: theme.spacing(2)
}));

const UserBoxText = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    paddingLeft: theme.spacing(1)
}));

const UserBoxDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light
}));

const UserBoxDescriptionMain = styled(Typography)(({ theme }) => ({
    color: theme.colors.alpha.trueWhite[50]
}));

const UserBoxLabel = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    display: 'block'
}));

const UserBoxLabelMain = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    display: 'block',
    color: theme.colors.alpha.trueWhite[100]
}));

const menuEntries: MenuEntry[] = [
    {
        title: 'Billing',
        icon: <CreditCardIcon fontSize="small" />,
        path: '/user/billing'
    },
    {
        title: 'Profile',
        icon: <AccountBoxOutlinedIcon fontSize="small" />,
        path: '/user/profile'
    }
];

function UserBox() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = useAuth();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            dispatch(clearCredentials());
            navigate('/');
        } catch (err) {
            console.error(err);
        }
    };

    const renderMenuEntry = (entry: MenuEntry) => {
        return (
            <ListItem
                key={entry.path}
                onClick={() => {
                    handleClose();
                }}
                button
                to={entry.path}
                component={NavLink}
            >
                {entry.icon}
                <ListItemText primary={t(entry.title)} />
            </ListItem>
        );
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <UserBoxButton fullWidth color="secondary" ref={ref} onClick={handleOpen}>
                <SecureAvatar src={recruitmentService.getRecruiterAvatarUrl(user.id)} variant="rounded" alt={user.name} />
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box
                        component="span"
                        sx={{
                            display: { xs: 'none', md: 'inline-block' }
                        }}
                    >
                        <UserBoxText>
                            <UserBoxLabelMain variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                                {user.name}
                            </UserBoxLabelMain>
                        </UserBoxText>
                    </Box>
                    <UnfoldMoreTwoToneIcon
                        fontSize="small"
                        sx={{
                            ml: 1
                        }}
                    />
                </Box>
            </UserBoxButton>
            <UserBoxPopover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MenuUserBox display="flex" alignItems="center">
                    <SecureAvatar src={recruitmentService.getRecruiterAvatarUrl(user.id)} variant="rounded" alt={user.name} />
                    <Box component="span" pr="24px">
                        <UserBoxText>
                            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
                        </UserBoxText>
                    </Box>
                </MenuUserBox>
                <Divider />
                <List
                    sx={{
                        p: 1
                    }}
                    component="nav"
                >
                    {menuEntries.map((entry) => renderMenuEntry(entry))}
                </List>
                <Divider />
                <Box m={1}>
                    <Button color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon
                            sx={{
                                mr: 1
                            }}
                        />
                        {t('Sign out')}
                    </Button>
                </Box>
            </UserBoxPopover>
        </>
    );
}

export default UserBox;

import { Box, Grid, Link, Typography } from '@mui/material';

import AuthLayout from 'layouts/AuthLayout';
import LogInForm from 'pages/Auth/Login/LogInForm';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

function LoginPage() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Log in | Teamsharq</title>
            </Helmet>
            <AuthLayout
                imgSrc="/static/images/secure_login.svg"
                imgAlt="Secure Login"
                subtitle={t('Ready to test new Candidates? Log in to your recruiter account.')}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" mb={1} textAlign="center">
                        {t('Log in')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LogInForm />
                </Grid>
                <Grid item container justifyContent="space-between">
                    <Grid item>
                        <Typography component="span" variant="subtitle2" color="text.primary">
                            {t("Don't have an account?")}
                        </Typography>{' '}
                        <Box display={{ xs: 'block', md: 'inline-block' }}>
                            <Link component={RouterLink} to="/signup">
                                {t('Sign up here')}
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Link component={RouterLink} to="/reset-password">
                            {t('Forgot password?')}
                        </Link>
                    </Grid>
                </Grid>
            </AuthLayout>
        </>
    );
}

export default LoginPage;

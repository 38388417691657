import { GAAction, GACategory } from 'models/analytics';
import { createContext, PropsWithChildren, useMemo, useState } from 'react';

import { createTheme, PaletteMode, Theme, ThemeProvider, useMediaQuery } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import ReactGA from 'react-ga4-pro';

import { TeamsharqLightTheme, TeamsharqDarkTheme } from './schemes';
import { Environment } from 'environment';

export enum ThemeMode {
    light = 'light',
    dark = 'dark'
}

const getDesignTokens = (mode: PaletteMode) => ({
    ...(mode === ThemeMode.light ? TeamsharqLightTheme : TeamsharqDarkTheme)
});

export const isLightMode = (theme: Theme) => theme.palette.mode === ThemeMode.light;

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

const ThemeProviderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
    const prefersDarkMode = useMediaQuery(`(prefers-color-scheme: ${ThemeMode.dark})`);
    const themeLS = localStorage.getItem(Environment.themeKey) as PaletteMode;

    const [mode, setMode] = useState<PaletteMode>(themeLS ? themeLS : prefersDarkMode ? ThemeMode.dark : ThemeMode.light);

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => {
                    const newMode = prevMode === ThemeMode.light ? ThemeMode.dark : ThemeMode.light;

                    ReactGA.event({
                        category: GACategory.Profile,
                        action: GAAction.ToggleTheme,
                        label: newMode
                    });

                    localStorage.setItem(Environment.themeKey, newMode);
                    return newMode;
                });
            }
        }),
        []
    );

    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <StylesProvider injectFirst>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </ColorModeContext.Provider>
        </StylesProvider>
    );
};

export default ThemeProviderWrapper;

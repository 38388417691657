import SuspenseLoader from 'components/SuspenseLoader';
import { lazy } from 'react';

const Subscriptions = SuspenseLoader(lazy(() => import('pages/Subscription')));
const Success = SuspenseLoader(lazy(() => import('pages/Subscription/Success')));

const subscriptionRoutes = [
    {
        path: 'select',
        element: <Subscriptions />
    },
    {
        path: 'success',
        element: <Success />
    }
];

export default subscriptionRoutes;

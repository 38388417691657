import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Environment } from 'environment';
import { BroadcastMessage, BroadcastMessageType } from 'models/broadcastMessage';
import axiosBaseQuery from 'store/axiosBaseQuery';

const broadcastService = {
    connectToBroadcastStream: (sessionId: string, userId: string, token: string): WebSocket => {
        const streamUrl = `${Environment.apiUrl.replace(
            'https',
            'wss'
        )}/tenants/one/cmit/courses/one/${sessionId}/users/one/${userId}/broadcast/stream`;
        return new WebSocket(streamUrl);
    },

    processBroadcastMessage: (
        messageTypes: BroadcastMessageType[],
        broadcastStream: WebSocket,
        process: (msg: BroadcastMessage) => void
    ): ((message: MessageEvent) => void) => {
        return (message: MessageEvent) => {
            if (message.data === '') {
                return;
            }

            const broadcastMessage: BroadcastMessage = JSON.parse(message.data);
            if (messageTypes.includes(broadcastMessage.type)) {
                broadcastStream.send('ack');
                process(broadcastMessage);
            }
        };
    }
};

export default broadcastService;

enum TagType {
    Broadcast = 'broadcast'
}

export const broadcastApiService = createApi({
    reducerPath: 'broadcastApiService',
    baseQuery: axiosBaseQuery({ basePath: '/tenants' }),
    tagTypes: [TagType.Broadcast],
    endpoints: (builder) => ({
        sendBroadcastMessage: builder.mutation<null, { sessionId: string; userId: string; message: BroadcastMessage }>({
            query: ({ sessionId, userId, message }) => ({
                url: `/one/cmit/courses/one/${sessionId}/users/one/${userId}/broadcast`,
                method: 'put',
                data: message
            })
        })
    })
});

export const { useSendBroadcastMessageMutation } = broadcastApiService;

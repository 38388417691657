import { Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TermsLabel: FC = () => {
    const { t } = useTranslation();

    return (
        <Typography variant="body2">
            {t('I accept the')}{' '}
            <Link component="a" target="_blank" href="https://teamsharq.com/terms">
                {t('Terms and Conditions')}
            </Link>{' '}
            {t('and')}{' '}
            <Link component="a" target="_blank" href="https://teamsharq.com/privacy">
                {t('Privacy Policy')}
            </Link>
            .
        </Typography>
    );
};

export default TermsLabel;

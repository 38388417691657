import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Environment } from 'environment';
import SignUpForm from './SignUpForm';
import AuthLayout from 'layouts/AuthLayout';

function SignUp() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Sign Up | Teamsharq</title>
            </Helmet>
            <AuthLayout
                imgSrc="/static/images/sign_up.svg"
                imgAlt="Forgot password"
                subtitle={t('Create a new account and start testing your candidates with tools they are familiar with.')}
            >
                <Grid item xs={12}>
                    <Typography variant="h2" mb={1} textAlign="center">
                        {t('Sign Up')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <GoogleReCaptchaProvider reCaptchaKey={Environment.recaptchaKey}>
                        <SignUpForm />
                    </GoogleReCaptchaProvider>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="span" variant="subtitle2" color="text.primary">
                        {t('Already have an account?')}{' '}
                        <Link component={RouterLink} to="/login">
                            {t('Log in')}
                        </Link>
                    </Typography>
                </Grid>
            </AuthLayout>
        </>
    );
}

export default SignUp;

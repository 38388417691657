import { createListenerMiddleware } from '@reduxjs/toolkit';
import { Environment } from 'environment';
import { userFromDTO } from 'models/user';
import { authApiService } from 'services/authService';
import { paymentApiService } from 'services/paymentService';
import { userApiService } from 'services/userService';
import { clearCredentials, initAuth, setCredentials } from 'store/slices/authSlice';
import axios, { tokenHeaderKey } from 'utils/axios';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: initAuth,
    effect: async (action, listenerApi) => {
        const token = localStorage.getItem(Environment.accessTokenKey) ?? null;

        if (token) {
            axios.defaults.headers.common[tokenHeaderKey] = token;

            const user = await listenerApi
                .dispatch(userApiService.endpoints.getMyDetails.initiate())
                .unwrap()
                .then(userFromDTO);

            listenerApi.dispatch(setCredentials({ user, token }));
        } else {
            listenerApi.dispatch(setCredentials({ initialized: true }));
        }
    }
});
listenerMiddleware.startListening({
    matcher: authApiService.endpoints.logIn.matchFulfilled,
    effect: async (action) => {
        axios.defaults.headers.common[tokenHeaderKey] = action.payload.access_token;
        localStorage.setItem(Environment.accessTokenKey, action.payload.access_token);
    }
});
listenerMiddleware.startListening({
    actionCreator: clearCredentials,
    effect: async () => {
        localStorage.removeItem(Environment.accessTokenKey);
    }
});

export default listenerMiddleware;

import { forwardRef } from 'react';

import { Avatar, AvatarProps } from '@mui/material';

import { useGetImageFileQuery } from 'services/fileService';

const SecureAvatar = forwardRef<typeof Avatar, AvatarProps>(({ src, children, ...props }, _unusedForwardRefProps) => {
    const { data: imageUrl } = useGetImageFileQuery(src!, { skip: !src });

    return (
        <Avatar {...props} src={imageUrl}>
            {children}
        </Avatar>
    );
});

export default SecureAvatar;

import { Environment } from 'environment';
import ReactGA from 'react-ga4-pro';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';

import Error from 'pages/Status/Error';
import { SidebarProvider } from 'contexts/SidebarContext';
import { store } from 'store';
import App from './App';
import './index.css';

if (Environment.gaMeasurementId) {
    ReactGA.initialize(Environment.gaMeasurementId, { formatEvents: false });
} else {
    console.warn('GA_MEASUREMENT_ID not set');
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
    <ErrorBoundary FallbackComponent={Error}>
        <HelmetProvider>
            <Provider store={store}>
                <SidebarProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SidebarProvider>
            </Provider>
        </HelmetProvider>
    </ErrorBoundary>
);

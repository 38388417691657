import Login from 'pages/Auth/Login';
import SignUp from 'pages/Auth/SignUp';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import Authenticated from 'components/Auth/Authenticated';
import SuspenseLoader from 'components/SuspenseLoader';

import BasicLayout from 'layouts/BasicLayout';
import MainLayout from 'layouts/MainLayout';

import Status404 from 'pages/Status/Status404';

import subscriptionRoutes from 'router/subscription';
import userRoutes from 'router/user';
import recruitmentsRoutes from './recruitments';

const WorkspaceClient = SuspenseLoader(lazy(() => import('pages/Workspaces/client')));
const ResetPassword = SuspenseLoader(lazy(() => import('pages/Auth/ResetPassword')));
const ChangePassword = SuspenseLoader(lazy(() => import('pages/Auth/ChangePassword')));
const VerifyEmail = SuspenseLoader(lazy(() => import('pages/Auth/VerifyEmail')));
const JoinInterview = SuspenseLoader(lazy(() => import('pages/Candidate/JoinInterview')));
const Workspaces = SuspenseLoader(lazy(() => import('pages/Workspaces/my')));

const routes: RouteObject[] = [
    {
        path: '*',
        element: <Status404 />
    },
    {
        path: '/signup',
        element: <SignUp />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/reset-password',
        children: [
            {
                path: '',
                element: <ResetPassword />
            },
            {
                path: ':tenantId/:recruiterId/:code',
                element: <ChangePassword />
            }
        ]
    },
    {
        path: '/verify-email/:tenantId/:recruiterId/:code',
        element: <VerifyEmail />
    },
    {
        path: '/join-interview/:tenantId/:recruitmentId/:sessionId/:code',
        element: (
            <BasicLayout>
                <JoinInterview />
            </BasicLayout>
        )
    },
    {
        path: '/',
        element: (
            <Authenticated>
                <MainLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="recruitments" replace />
            },

            {
                path: 'recruitments',
                children: recruitmentsRoutes
            },
            {
                path: 'workspaces',
                element: <Workspaces />
            },
            {
                path: 'user',
                children: userRoutes
            },
            {
                path: 'subscription',
                children: subscriptionRoutes
            }
        ]
    },
    {
        path: '/workspace',
        element: <BasicLayout />,
        children: [
            {
                path: ':recruitmentId/:sessionId',
                element: (
                    <Authenticated>
                        <WorkspaceClient />
                    </Authenticated>
                )
            },
            {
                path: 'candidate/:recruitmentId/:sessionId/:code',
                element: <WorkspaceClient />
            }
        ]
    }
];

export default routes;

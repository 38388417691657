import { ComponentType, FC, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { CssBaseline, Zoom } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';

import 'i18n/i18n';
import routes from 'router';
import { useAuth } from 'hooks/useAuth';
import AppInit from 'components/AppInit';
import { useAppDispatch } from 'hooks/store';
import ThemeProvider from 'theme/ThemeProvider';
import { initAuth } from 'store/slices/authSlice';
import { DialogProvider } from 'contexts/DialogContext';
import i18n from 'i18n/i18n';
import { localesType, resources } from 'i18n/languages';

const App: FC = () => {
    const content = useRoutes(routes);
    const dispatch = useAppDispatch();
    const auth = useAuth();

    useEffect(() => {
        dispatch(initAuth());

        if (Object.keys(resources).includes(i18n.language)) {
            resources[i18n.language as localesType].dayjsLocale.then(() => {
                dayjs.locale(i18n.language);
            });
        }
        dayjs.extend(localizedFormat);
        dayjs.extend(isBetween);
        dayjs.extend(duration);
        dayjs.extend(relativeTime);
    }, []);

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    TransitionComponent={Zoom as ComponentType}
                >
                    <DialogProvider>
                        <>
                            <CssBaseline />
                            {auth.initialized ? content : <AppInit />}
                        </>
                    </DialogProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;

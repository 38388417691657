import { FC, PropsWithChildren, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { alpha, Box, Card, Container, Drawer, styled, useTheme } from '@mui/material';

import Logo from 'components/LogoSign';
import Scrollbar from 'components/Scrollbar';
import { SidebarContext } from 'contexts/SidebarContext';
import TopBar from './TopBar';
import { ThemeMode } from 'theme/ThemeProvider';

const MainWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0, 4),

    '.MuiDrawer-fm .MuiPaper-root': {
        top: 0,
        height: '100%'
    },

    '.Mui-FixedWrapper .MuiPaper-root': {
        top: 0,
        left: 0
    },

    '.MuiDrawer-hd .MuiPaper-root': {
        top: 0,
        height: '100%'
    },

    '.footer-wrapper': {
        borderTop: `2px ${theme.colors.alpha.black[30]}`,
        backgroundColor: 'transparent'
    }
}));

const MainContent = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(-48),
    position: 'relative',
    zIndex: 55
}));

const CardWrapper = styled(Card)(({ theme }) => ({
    background: `linear-gradient(${theme.palette.mode === ThemeMode.light ? '#f3f3f3' : '#1B1B23'} 340px, ${
        theme.colors.alpha.white[100]
    } 380px)`,
    borderRadius: theme.general.borderRadiusXl
}));

const SidebarWrapper = styled(Box)(({ theme }) => ({
    width: theme.sidebar.width,
    minWidth: theme.sidebar.width,
    color: theme.sidebar.textColor,
    background: theme.sidebar.background,
    boxShadow: theme.sidebar.boxShadow,
    position: 'relative',
    zIndex: 5,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
        height: `calc(100% - ${theme.header.height})`,
        marginTop: theme.header.height
    }
}));

const TopSection = styled(Box)(({ theme }) => ({
    margin: theme.spacing(2, 2)
}));

const MainLayout: FC<PropsWithChildren> = () => {
    const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();

    return (
        <>
            <MainWrapper>
                <TopBar />
                <MainContent maxWidth="xl">
                    <Box mx={{ sm: 0, md: 5, lg: 10, xl: 15 }}>
                        <CardWrapper>
                            <Outlet />
                        </CardWrapper>
                    </Box>
                    <Drawer
                        sx={{
                            display: { lg: 'none', xs: 'inline-block' }
                        }}
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={isSidebarOpen}
                        onClose={closeSidebar}
                        variant="temporary"
                        elevation={9}
                    >
                        <SidebarWrapper>
                            <Scrollbar>
                                <TopSection>
                                    <Box
                                        sx={{
                                            width: 52,
                                            ml: 1,
                                            mt: 1,
                                            mb: 3
                                        }}
                                    >
                                        <Logo />
                                    </Box>
                                </TopSection>
                            </Scrollbar>
                        </SidebarWrapper>
                    </Drawer>
                </MainContent>
            </MainWrapper>
        </>
    );
};

export default MainLayout;

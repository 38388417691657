import enJSON from 'i18n/locales/en/translation.json';
import plJSON from 'i18n/locales/pl/translation.json';

export const resources = {
    en: { translation: enJSON, dayjsLocale: import('dayjs/locale/en') },
    pl: { translation: plJSON, dayjsLocale: import('dayjs/locale/pl') }
} as const;

export type localesType = keyof typeof resources;
export const locales = Object.keys(resources);

import { Box, Card, Grid, styled, Typography, useTheme } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { FC, PropsWithChildren } from 'react';
import Logo from './MainLayout/TopBar/Logo';

const Content = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    width: '100%'
}));

const SidebarWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none'
    },
    position: 'fixed',
    left: 0,
    top: 0,
    width: 440,
    height: '100%',
    background: theme.colors.alpha.white[100],
    borderRight: `1px solid ${theme.colors.primary.lighter}`
}));

const SidebarContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6)
}));

const MainContent = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        margin: '0'
    },
    display: 'flex',
    flex: 1,
    width: '100%',
    marginLeft: 440,
    padding: theme.spacing(6),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url("static/images/auth-background.svg")`
}));

const MainContentCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(5, 4, 3),
    maxWidth: theme.breakpoints.values.sm
}));

interface AuthLayoutProps extends PropsWithChildren {
    subtitle?: string;
    imgSrc?: string;
    imgAlt?: string;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children, subtitle, imgSrc, imgAlt }) => {
    const theme = useTheme();

    return (
        <Content>
            <SidebarWrapper
                sx={{
                    display: { xs: 'none', md: 'flex' }
                }}
            >
                <Scrollbar>
                    <SidebarContent>
                        <Logo variant={theme.palette.mode} />
                        <Box mt={10}>
                            <Box sx={{ textAlign: 'center' }}>{imgSrc && <img alt={imgAlt} width="100%" src={imgSrc} />}</Box>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    fontSize: theme.typography.pxToRem(20),
                                    mt: 8,
                                    textAlign: 'center'
                                }}
                            >
                                {subtitle}
                            </Typography>
                        </Box>
                    </SidebarContent>
                </Scrollbar>
            </SidebarWrapper>

            <MainContent>
                <MainContentCard>
                    <Grid container spacing={4}>
                        {children}
                    </Grid>
                </MainContentCard>
            </MainContent>
        </Content>
    );
};

export default AuthLayout;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'components/SuspenseLoader';
import Authorized from 'components/Auth/Authorized';

const MyRecruitments = SuspenseLoader(lazy(() => import('pages/Recruitments/my')));
const RecruitmentView = SuspenseLoader(lazy(() => import('pages/Recruitments/single/view')));

const recruitmentsRoutes = [
    {
        path: '',
        element: <MyRecruitments />
    },
    {
        path: 'single',
        children: [
            {
                path: ':recruitmentId/edit',
                element: (
                    <Authorized redirectPath={'recruitments'} onlyRecruitmentOwner>
                        <RecruitmentView />
                    </Authorized>
                )
            },
            {
                path: ':recruitmentId',
                element: <RecruitmentView />
            }
        ]
    }
];

export default recruitmentsRoutes;

import SuspenseLoader from 'components/SuspenseLoader';
import { lazy } from 'react';

const Billing = SuspenseLoader(lazy(() => import('pages/User/billing')));
const Profile = SuspenseLoader(lazy(() => import('pages/User/profile')));

const userRoutes = [
    {
        path: 'billing',
        element: <Billing />
    },
    {
        path: 'profile',
        element: <Profile />
    }
];

export default userRoutes;

import { Box, Link, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    darkBackground?: boolean;
}

const NoSubscriptionBox: FC<Props> = ({ darkBackground }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Typography
                variant="subtitle2"
                sx={{
                    whiteSpace: 'nowrap',
                    color: darkBackground ? theme.palette.primary.contrastText : theme.palette.text.primary
                }}
            >
                {t('No active subscription.')}
            </Typography>
            <Link
                component={RouterLink}
                sx={{
                    fontWeight: 'bold',
                    color: darkBackground ? theme.palette.primary.light : theme.palette.primary.main
                }}
                to="/user/billing"
            >
                {t('Go to Billing')}
            </Link>
        </Box>
    );
};

export default NoSubscriptionBox;

import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

const BasicLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                // flex: 1,
                width: 1,
                height: 1
            }}
        >
            {children || <Outlet />}
        </Box>
    );
};

export default BasicLayout;

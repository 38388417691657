import { createApi } from '@reduxjs/toolkit/query/react';

import { Environment } from 'environment';
import { AuthPayload, AuthSession } from 'models/session';
import { UserAddPayload, ResetPasswordPayload, ChangePasswordPayload } from 'models/user';
import { VerifyEmailPayload } from 'models/user';
import axiosBaseQuery from 'store/axiosBaseQuery';

const authService = {
    accessToken: (): string => localStorage.getItem(Environment.accessTokenKey) ?? ''
};

export default authService;

export const authApiService = createApi({
    reducerPath: 'authApiService',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        logIn: builder.mutation<AuthSession, AuthPayload>({
            query: (payload) => ({ url: '/auth/login', method: 'post', data: payload })
        }),
        checkEmailAvailability: builder.query<void, string>({
            query: (login) => ({
                url: `/tenants/one/cmit/recruiters/logins/available`,
                params: { login },
                method: 'get'
            })
        }),
        signUp: builder.mutation<AuthSession, UserAddPayload>({
            query: ({ recaptcha, ...data }) => ({
                url: '/recruiters',
                method: 'post',
                data: {
                    rodo_consent: true,
                    public_consent: true,
                    terms: true,
                    privacy_policy: true,
                    ...data
                },
                headers: { 'x-api-recaptcha': recaptcha }
            })
        }),
        resetPassword: builder.mutation<AuthSession, ResetPasswordPayload>({
            query: ({ email }) => ({
                url: `/tenants/one/cmit/recruiters/by/email/${email}/credentials/verify/start`,
                method: 'put'
            })
        }),
        changePassword: builder.mutation<void, ChangePasswordPayload>({
            query: ({ recruiterId, code, ...data }) => ({
                url: `/tenants/one/cmit/recruiters/one/${recruiterId}/credentials`,
                method: 'put',
                params: { code },
                data
            })
        }),
        verifyEmail: builder.query<AuthSession, VerifyEmailPayload>({
            query: ({ tenantId, recruiterId, code }) => ({
                url: `/tenants/one/${tenantId}/recruiters/one/${recruiterId}/verify`,
                method: 'put',
                params: { code }
            })
        })
    })
});

export const {
    useLogInMutation,
    useSignUpMutation,
    useLazyCheckEmailAvailabilityQuery,
    useResetPasswordMutation,
    useChangePasswordMutation,
    useVerifyEmailQuery
} = authApiService;

import LoadingButton from 'components/LoadingButton';

import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import ReactGA from 'react-ga4-pro';
import * as Yup from 'yup';

import { userFromDTO } from 'models/user';
import { useAppDispatch } from 'hooks/store';

import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLogInMutation } from 'services/authService';
import { useLazyGetMyDetailsQuery } from 'services/userService';
import { setCredentials } from 'store/slices/authSlice';
import { Grid } from '@mui/material';
import { trimFormikValues } from 'utils/trimValues';

const LogInForm: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const location = useLocation();
    const navigate = useNavigate();
    const from = (location.state as { from: Location })?.from?.pathname ?? '/';

    const [getMyDetails] = useLazyGetMyDetailsQuery();
    const [logIn] = useLogInMutation();

    const initialFormValues: FormikValues = {
        email: '',
        password: ''
    };

    const formSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('The email provided should be a valid email address'))
            .max(255)
            .required(t('The email field is required')),
        password: Yup.string().max(255).required(t('The password field is required'))
    });

    const submitForm = async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
        try {
            const session = await logIn({ email: values.email, password: values.password, tenant: 'cmit' }).unwrap();
            const user = await getMyDetails().unwrap().then(userFromDTO);
            dispatch(
                setCredentials({
                    token: session.access_token,
                    user
                })
            );

            ReactGA.event('login');

            navigate(from, { replace: true });
        } catch (e) {
            console.error(e);
            enqueueSnackbar(t('Log in failed.'), { variant: 'error' });
        }
    };

    return (
        <Formik initialValues={initialFormValues} validationSchema={formSchema} onSubmit={trimFormikValues(submitForm)}>
            {({ isSubmitting }) => (
                <Form noValidate>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Field
                                component={FormikTextField}
                                fullWidth
                                label={t('Email address')}
                                name="email"
                                type="email"
                                variant="outlined"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={FormikTextField}
                                fullWidth
                                label={t('Password')}
                                name="password"
                                type="password"
                                variant="outlined"
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <LoadingButton
                                loading={isSubmitting}
                                color="primary"
                                disabled={isSubmitting}
                                type="submit"
                                size="large"
                                variant="contained"
                                sx={{ width: 0.5 }}
                            >
                                {t('Log in')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default LogInForm;

import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

interface Props {
    children: JSX.Element;
}

const Authenticated: FC<Props> = ({ children }) => {
    const { token } = useAuth();

    let location = useLocation();

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default Authenticated;

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { alpha, Tooltip, useTheme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    darkBackground?: boolean;
}

const NoFreeWorkspaceSlotsInfo: FC<Props> = ({ darkBackground }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Tooltip
            title={t(
                'All of your workspace slots are currently occupied. To add more slots - go to Billing, click Manage and update the quantity in your subscription.'
            )}
            arrow
            placement="bottom"
        >
            <HelpOutlineIcon
                sx={{
                    fontSize: theme.typography.pxToRem(17),
                    color: alpha(
                        darkBackground || theme.palette.mode === 'dark'
                            ? theme.palette.primary.contrastText
                            : theme.palette.text.primary,
                        0.5
                    )
                }}
            />
        </Tooltip>
    );
};

export default NoFreeWorkspaceSlotsInfo;

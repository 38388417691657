import { Box, useTheme } from '@mui/material';

import Logo from 'components/LogoSign';

function AppInit() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                height: '100%'
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Logo variant={theme.palette.mode} />
        </Box>
    );
}

export default AppInit;

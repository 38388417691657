import { CSSProperties, FC } from 'react';
import { GridLoader } from 'react-spinners';

import { useTheme } from '@mui/material';

interface Props {
    loading?: boolean;
    size?: number;
}

const Loader: FC<Props> = ({ loading = true, size = 32 }) => {
    const theme = useTheme();

    const gridLoaderStyles: CSSProperties = {
        display: 'block',
        margin: '0 auto',
        padding: `${theme.spacing(12)} 0`
    };

    return (
        <GridLoader
            data-cy="loader"
            cssOverride={gridLoaderStyles}
            loading={loading}
            color={theme.colors.primary.main}
            size={size}
            margin={2}
        />
    );
};

export default Loader;

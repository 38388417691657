import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Container, Button, styled, useTheme } from '@mui/material';

import Logo from 'components/LogoSign';
import { CustomFade } from 'components/Transitions/Fade';

const MainContent = styled(Box)({
    height: '100%',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
});

function Status404() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>Status - 404</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="md">
                    <CustomFade isFetching={false}>
                        <Box display="flex" gap={4} flexDirection="column" textAlign="center">
                            <Logo variant={theme.palette.mode} />
                            <img alt="404" height={180} src="/static/images/status/404.svg" />
                            <Typography
                                variant="h2"
                                sx={{
                                    my: 2
                                }}
                            >
                                {t("The page you were looking for doesn't exist.")}
                            </Typography>
                            <Box>
                                <Button component={Link} to="/" variant="outlined">
                                    {t('Go to homepage')}
                                </Button>
                            </Box>
                        </Box>
                    </CustomFade>
                </Container>
            </MainContent>
        </>
    );
}

export default Status404;

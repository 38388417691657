import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, styled } from '@mui/material';

interface LogoProps {
    variant?: 'light' | 'dark';
}

const LogoWrapper = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    display: 'flex',
    textDecoration: 'none',
    width: 250,
    margin: '0 auto',
    fontWeight: theme.typography.fontWeightBold
}));

const LogoSignWrapper = styled(Box)({
    width: 250
});

const Logo: FC<LogoProps> = ({ variant = 'light' }) => {
    return (
        <LogoWrapper to="/">
            <LogoSignWrapper>
                <img src={`static/images/logo/teamsharq-logo-full-${variant}.svg`} alt="logo" />
            </LogoSignWrapper>
        </LogoWrapper>
    );
};

export default Logo;

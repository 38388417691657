import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user';

type AuthState = {
    initialized: boolean;
    user: User | null;
    token: string | null;
};

const initialState: AuthState = { initialized: false, user: null, token: null };

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initAuth: () => initialState,
        setCredentials: (state, { payload }: PayloadAction<Partial<AuthState>>) => ({
            ...state,
            ...payload,
            initialized: true
        }),
        clearCredentials: () => ({ ...initialState, initialized: true })
    }
});

export const { initAuth, setCredentials, clearCredentials } = slice.actions;

export default slice.reducer;

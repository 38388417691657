import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC } from 'react';

interface Props extends ButtonProps {
    loading: boolean;
}

// TODO: https://github.com/mui/material-ui/issues/31235

const LoadingButton: FC<Props> = ({ loading, children, ...props }) => {
    const loadingProps = loading
        ? {
              startIcon: <CircularProgress size="1rem" />,
              disabled: true
          }
        : {};

    return (
        <Button {...props} {...loadingProps}>
            {children}
        </Button>
    );
};

export default LoadingButton;

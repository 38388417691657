import { createApi } from '@reduxjs/toolkit/query/react';
import { UserCredentialsPayload, UserDTO, UserPrivacySettings, UserProfilePayload } from 'models/user';
import axiosBaseQuery from 'store/axiosBaseQuery';

const userService = {
    getUserAvatarUrl: (userId: string, preview: boolean = false): string => {
        return `${preview ? '/tenants/one/cmit' : ''}/users/one/${userId}/avatar/stream`;
    }
};

export default userService;

enum TagType {
    Users = 'users'
}

export const userApiService = createApi({
    reducerPath: 'userApiService',
    baseQuery: axiosBaseQuery({ basePath: '/recruiters' }),
    tagTypes: [TagType.Users],
    endpoints: (builder) => ({
        getMyDetails: builder.query<UserDTO, void>({
            query: () => ({ url: `/alias/me`, method: 'get' }),
            providesTags: (user) => [{ type: TagType.Users, id: user?.id }]
        }),
        updateMyCredentials: builder.mutation<void, UserCredentialsPayload>({
            query: (data) => ({ url: `/alias/me/credentials`, method: 'put', data })
        }),
        updateMyProfile: builder.mutation<UserDTO, UserProfilePayload>({
            query: (data) => ({ url: `/alias/me`, method: 'put', data }),
            invalidatesTags: (user) => [{ type: TagType.Users, id: user?.id }]
        }),
        getMyPrivacySettings: builder.query<UserPrivacySettings, void>({
            query: () => ({ url: `/alias/me/consents`, method: 'get' })
        }),
        updateMyPrivacySettings: builder.mutation<void, UserPrivacySettings>({
            query: (data) => ({ url: `/alias/me/consents`, method: 'put', data })
        })
    })
});

export const {
    useGetMyDetailsQuery,
    useLazyGetMyDetailsQuery,
    useUpdateMyCredentialsMutation,
    useUpdateMyProfileMutation,
    useGetMyPrivacySettingsQuery,
    useUpdateMyPrivacySettingsMutation
} = userApiService;

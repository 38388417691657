import { FC, forwardRef, PropsWithChildren } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, SxProps, useTheme } from '@mui/material';

const Scrollbar = forwardRef<Scrollbars, Partial<PropsWithChildren<FC>>>(({ children, ...rest }, ref) => {
    const theme = useTheme();

    const boxStyles: SxProps = {
        width: 5,
        background: theme.colors.alpha.black[10],
        borderRadius: theme.general.borderRadiusLg,
        transition: theme.transitions.create(['background']),

        '&:hover': {
            background: theme.colors.alpha.black[30]
        }
    };

    return (
        <Scrollbars
            ref={ref}
            autoHide
            renderThumbVertical={() => {
                return <Box sx={boxStyles} />;
            }}
            {...rest}
        >
            {children}
        </Scrollbars>
    );
});

export default Scrollbar;

interface UserBase {
    id: string;
    tenant: string;
    login: string;
    status: UserStatus;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    iam_instructor: boolean;
    iam_student: boolean;
}

export interface User extends UserBase {
    name: string;
}

export interface UserDTO extends UserBase {}

export const userFromDTO = (dto: UserDTO): User => {
    return {
        ...dto,
        name: `${dto.first_name} ${dto.last_name}`
    };
};

export interface UserLoginPayload {
    login: string;
    password: string;
    remember: boolean;
}

export interface UserAddPayload {
    tenant: string;
    login: string;
    password: string;
    first_name: string;
    last_name: string;
    email: string;
    recaptcha: string;
}

export interface ResetPasswordPayload {
    email: string;
}

export interface ChangePasswordPayload {
    recruiterId: string;
    code: string;
    password: string;
}

export interface UserCredentialsPayload {
    current_password?: string;
    password: string;
}

export interface VerifyEmailPayload {
    tenantId: string;
    recruiterId: string;
    code: string;
}

export interface UserProfilePayload {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
}

export interface UserPrivacySettings {
    rodo_consent: boolean;
    public_consent: boolean;
}

export enum UserStatus {
    Active = 'ACTIVE',
    Blocked = 'BLOCKED',
    Deleted = 'DELETED'
}

export enum AuthRole {
    Instructor = 'instructor',
    Student = 'student',
    Manager = 'manager'
}

export enum VerificationStatus {
    Success = 'success',
    Error = 'error'
}

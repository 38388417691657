import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

import {
    CandidateInvitation,
    Invitation,
    RecruiterInvitation,
    RecruiterRemoval,
    Recruitment,
    RecruitmentDTO,
    SessionDeletePayload,
    SessionDTO,
    SessionParticipant,
    SessionParticipants,
    SessionPayload,
    SessionUpdatePayload
} from 'models/recruitment';
import { CreateWorkspacePayload, Workspace, WorkspaceAction, WorkspaceConnectionDetails, WorkspaceId } from 'models/workspace';
import axiosBaseQuery from 'store/axiosBaseQuery';

export const recruitmentService = {
    recruiterUploadAvatarUrl: '/recruiters/alias/me/avatar/stream',
    getRecruitmentAvatarUrl: (recruitmentId: string) => {
        return `/recruitments/one/${recruitmentId}/avatar/stream`;
    },
    getRecruiterAvatarUrl: (recruiterId: string, me?: boolean) => {
        return `/tenants/one/cmit/recruiters/${me ? 'alias/me' : `one/${recruiterId}`}/avatar/stream`;
    },
    getCandidateFileUrl: (candidateId: string) => {
        return `/candidates/one/${candidateId}/file/stream`;
    }
};

enum TagType {
    Recruitment = 'recruitment',
    Session = 'session',
    Participant = 'participant'
}

enum TagId {
    List = 'list'
}

export const recruitmentApiService = createApi({
    reducerPath: 'recruitmentApiService',
    baseQuery: axiosBaseQuery({ basePath: '/recruitments' }),
    tagTypes: [TagType.Recruitment, TagType.Session, TagType.Participant],
    endpoints: (builder) => ({
        getMyRecruitments: builder.query<RecruitmentDTO[], void>({
            query: () => ({
                url: '/alias/my',
                method: 'get'
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: TagType.Recruitment, id } as const)),
                          { type: TagType.Recruitment, id: TagId.List }
                      ]
                    : [{ type: TagType.Recruitment, id: TagId.List }]
        }),
        getRecruitment: builder.query<RecruitmentDTO, string>({
            query: (recruitmentId) => ({ url: `/one/${recruitmentId}`, method: 'get' }),
            providesTags: (result, error, id) => [{ type: TagType.Recruitment, id }]
        }),
        addRecruitment: builder.mutation<RecruitmentDTO, Partial<Recruitment>>({
            query: (data) => ({
                url: '',
                method: 'post',
                data
            }),
            invalidatesTags: [{ type: TagType.Recruitment, id: TagId.List }]
        }),
        updateRecruitment: builder.mutation<RecruitmentDTO, Partial<Recruitment>>({
            query: (data) => ({
                url: `/one/${data.id}`,
                method: 'put',
                data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: TagType.Recruitment, id }]
        }),
        deleteRecruitment: builder.mutation<RecruitmentDTO, string>({
            query: (recruitmentId) => {
                return { url: `/one/${recruitmentId}`, method: 'delete' };
            },
            invalidatesTags: (result, error, id) => [{ type: TagType.Recruitment, id }]
        }),
        getRecruiterSession: builder.query<SessionDTO, Invitation>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}`,
                method: 'get'
            }),
            onQueryStarted({ recruitmentId }, { dispatch, queryFulfilled }) {
                queryFulfilled.then(({ data }) => dispatch(refreshSession(recruitmentId, data)));
            },
            providesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        getCandidateSession: builder.query<SessionDTO, WorkspaceId>({
            query: ({ recruitmentId, sessionId, code }) => ({
                basePathOverride: '/tenants',
                url: `/one/cmit/recruitments/one/${recruitmentId}/sessions/one/${sessionId}`,
                method: 'get',
                params: { code }
            }),
            providesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        addSession: builder.mutation<SessionDTO, SessionPayload>({
            query: ({ recruitmentId, ...data }) => ({
                url: `/one/${recruitmentId}/sessions`,
                method: 'post',
                data
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        updateSession: builder.mutation<SessionDTO, SessionUpdatePayload>({
            query: ({ recruitmentId, ...data }) => ({
                url: `/one/${recruitmentId}/sessions/one/${data.id}`,
                method: 'put',
                data
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        deleteSession: builder.mutation<SessionDTO, SessionDeletePayload>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}`,
                method: 'delete'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        inviteCandidate: builder.mutation<RecruitmentDTO, CandidateInvitation>({
            query: ({ recruitmentId, sessionId, candidate }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/invite/participant`,
                data: candidate,
                method: 'post'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        inviteRecruiter: builder.mutation<RecruitmentDTO, RecruiterInvitation>({
            query: ({ recruitmentId, sessionId, recruiter }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/invite/recruiter`,
                data: recruiter,
                method: 'post'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        removeRecruiter: builder.mutation<RecruitmentDTO, RecruiterRemoval>({
            query: ({ recruitmentId, sessionId, recruiterId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/recruiters/one/${recruiterId}`,
                method: 'delete'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        getRecruiterSessionParticipants: builder.query<SessionParticipant[], WorkspaceId>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/participants`,
                method: 'get'
            }),
            transformResponse: (response: SessionParticipants) => [
                { ...response.candidate, isCandidate: true },
                ..._.sortBy(response.recruiters, ['first_name', 'last_name'])
            ],
            providesTags: () => [{ type: TagType.Participant, id: TagId.List }]
        }),
        getCandidateSessionParticipants: builder.query<SessionParticipant[], WorkspaceId>({
            query: ({ recruitmentId, sessionId, code }) => ({
                basePathOverride: '/tenants',
                url: `/one/cmit/recruitments/one/${recruitmentId}/sessions/one/${sessionId}/participants`,
                method: 'get',
                params: { code }
            }),
            transformResponse: (response: SessionParticipants) => [
                { ...response.candidate, isCandidate: true },
                ..._.sortBy(response.recruiters, ['first_name', 'last_name'])
            ],
            providesTags: () => [{ type: TagType.Participant, id: TagId.List }]
        }),
        createWorkspace: builder.mutation<
            Workspace,
            { recruitmentId: string; sessionId: string; payload: CreateWorkspacePayload }
        >({
            query: ({ recruitmentId, sessionId, payload }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/vm`,
                method: 'post',
                data: payload
            }),
            async onQueryStarted({ recruitmentId, sessionId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: workspace } = await queryFulfilled;

                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getRecruitment',
                            recruitmentId,
                            (recruitment: RecruitmentDTO) => {
                                const sIdx = _.findIndex(recruitment.sessions, ['id', sessionId]);
                                Object.assign(recruitment.sessions[sIdx], { vm: workspace });
                            }
                        )
                    );
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getMyRecruitments',
                            undefined,
                            (recruitments: RecruitmentDTO[]) => {
                                const rIdx = _.findIndex(recruitments, ['id', recruitmentId]);
                                const sIdx = _.findIndex(recruitments[rIdx].sessions, ['id', sessionId]);
                                Object.assign(recruitments[rIdx].sessions[sIdx], { vm: workspace });
                            }
                        )
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        deleteWorkspace: builder.mutation<Workspace, { recruitmentId: string; sessionId: string }>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/vm`,
                method: 'delete',
                data: null
            }),
            async onQueryStarted({ recruitmentId, sessionId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getRecruitment',
                            recruitmentId,
                            (recruitment: RecruitmentDTO) => {
                                const sIdx = _.findIndex(recruitment.sessions, ['id', sessionId]);
                                Object.assign(recruitment.sessions[sIdx], { vm: null });
                            }
                        )
                    );
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getMyRecruitments',
                            undefined,
                            (recruitments: RecruitmentDTO[]) => {
                                const rIdx = _.findIndex(recruitments, ['id', recruitmentId]);
                                const sIdx = _.findIndex(recruitments[rIdx].sessions, ['id', sessionId]);
                                Object.assign(recruitments[rIdx].sessions[sIdx], { vm: null });
                            }
                        )
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        changeWorkspaceStatus: builder.mutation<
            Workspace,
            { recruitmentId: string; sessionId: string; action: WorkspaceAction }
        >({
            query: ({ recruitmentId, sessionId, action }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/vm/${action}`,
                method: 'put',
                data: null
            }),
            async onQueryStarted({ recruitmentId, sessionId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: workspace } = await queryFulfilled;
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getRecruitment',
                            recruitmentId,
                            (recruitment: RecruitmentDTO) => {
                                const sIdx = _.findIndex(recruitment.sessions, ['id', sessionId]);
                                Object.assign(recruitment.sessions[sIdx], { vm: workspace });
                            }
                        )
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        })
    })
});

export const refreshSession = (recruitmentId: string, session: SessionDTO): any => {
    return recruitmentApiService.util.updateQueryData('getRecruitment', recruitmentId, (recruitment: RecruitmentDTO) => {
        const sIdx = _.findIndex(recruitment.sessions, ['id', session.id]);
        Object.assign(recruitment.sessions[sIdx], session);
    });
};

export const {
    // Recruitment
    useGetMyRecruitmentsQuery,
    useGetRecruitmentQuery,
    useAddRecruitmentMutation,
    useUpdateRecruitmentMutation,
    useDeleteRecruitmentMutation,
    // Session
    useGetRecruiterSessionQuery,
    useLazyGetRecruiterSessionQuery,
    useLazyGetCandidateSessionQuery,
    useAddSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation,
    // Candidate / Recruiter Invitation
    useInviteCandidateMutation,
    useInviteRecruiterMutation,
    useRemoveRecruiterMutation,
    useLazyGetRecruiterSessionParticipantsQuery,
    useLazyGetCandidateSessionParticipantsQuery,
    // Workspace
    useCreateWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useChangeWorkspaceStatusMutation
} = recruitmentApiService;

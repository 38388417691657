import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import MonitorIcon from '@mui/icons-material/Monitor';
import { Box, List, ListItemButton, ListItemText, styled } from '@mui/material';
import { MenuEntry } from 'models/menu';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const MenuWrapper = styled(Box)(({ theme }) => ({
    '.MuiList-root': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',

        '.MuiListItemButton-root': {
            width: 'auto',
            backgroundColor: 'transparent',
            borderRadius: theme.general.borderRadiusLg,
            justifyContent: 'center',
            padding: theme.spacing(1.4, 2),
            position: 'relative',
            color: theme.colors.alpha.trueWhite[100],

            '.MuiListItemText-primary': {
                fontSize: theme.typography.pxToRem(16)
            },

            '.MuiSvgIcon-root': {
                transition: theme.transitions.create(['color']),
                fontSize: theme.typography.pxToRem(24),
                marginRight: theme.spacing(1),
                color: theme.colors.alpha.trueWhite[50]
            },

            '&.active, &:hover': {
                backgroundColor: theme.colors.alpha.trueWhite[10],

                '.MuiListItemText-primary': {
                    fontWeight: 'bold'
                },

                '.MuiSvgIcon-root': {
                    color: theme.colors.alpha.trueWhite[100]
                }
            }
        }
    }
}));

const menuEntries: MenuEntry[] = [
    {
        title: 'Recruitments',
        icon: <ListAltOutlinedIcon />,
        path: '/recruitments'
    },
    {
        title: 'Workspaces',
        icon: <MonitorIcon />,
        path: '/workspaces'
    }
];

function NavigationMenu() {
    const { t } = useTranslation();

    const renderMenuEntry = (entry: MenuEntry) => {
        return (
            <Box sx={{ px: 1 }} key={entry.path}>
                <ListItemButton to={entry.path} component={NavLink}>
                    {entry.icon}
                    <ListItemText primary={t(entry.title)} />
                </ListItemButton>
            </Box>
        );
    };

    return (
        <MenuWrapper mx={{ sm: 0, md: 5, lg: 0, xl: 15 }} pt={2}>
            <List component="div">{menuEntries.map((entry) => renderMenuEntry(entry))}</List>
        </MenuWrapper>
    );
}

export default NavigationMenu;
